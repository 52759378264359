import React, {useEffect} from 'react';
import {IModalProps} from "../../utils/type";
import {createPortal} from "react-dom";
import styles from "./modal-edit-picture.module.scss";

const modalRoot = document.getElementById('modal-root') as HTMLElement;

const ModalEditPicture = ({ title, children, active, setActive }: IModalProps) => {

  // Закрыть модалку при нажатии на Escape
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setActive(false);
      }
    };

    // Блокируем прокрутку страницы при открытом модальном окне
    if (active) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setActive]);

  if (!active) return null; // Не рендерить модалку, если она не активна

  return createPortal(
    (
      <div className={styles.modal} onClick={() => setActive(false)}>
        <div className={styles.modal__block} onClick={(e) => e.stopPropagation()}>
          <div className={styles.modal__heading}>
            <p className={styles.modal__title}>{title}</p>
            <div className={styles.modal__close} onClick={() => setActive(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M2.80365 2.80292C3.09655 2.51003 3.57142 2.51003 3.86431 2.80292L10.0007 8.93926L16.137 2.80292C16.4299 2.51003 16.9048 2.51003 17.1976 2.80292C17.4905 3.09582 17.4905 3.57069 17.1976 3.86358L11.0613 9.99992L17.1976 16.1363C17.4905 16.4291 17.4905 16.904 17.1976 17.1969C16.9048 17.4898 16.4299 17.4898 16.137 17.1969L10.0007 11.0606L3.86431 17.1969C3.57142 17.4898 3.09655 17.4898 2.80365 17.1969C2.51076 16.904 2.51076 16.4291 2.80365 16.1363L8.93999 9.99992L2.80365 3.86358C2.51076 3.57069 2.51076 3.09582 2.80365 2.80292Z"
                      fill="black"/>
              </svg>
            </div>
          </div>
          <div className={styles.modal__content}>
            {children}
          </div>
        </div>
        <div className={styles.modal__overlay} onClick={() => setActive(false)}></div>
      </div>
    ),
    modalRoot
  );
};

export default ModalEditPicture;