import { ChangeEvent } from "react";
import styles from "./input.module.scss";

interface InputProps {
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  type?: string; // Add type to props
  additionalClass?: string;
}

const Input = ({label,value,onChange,placeholder,type = "text",additionalClass = ''}: InputProps) => (
  <label className={`${styles.label} ${additionalClass}`}>
    <span className={styles.text}>{label}</span>
    <input
      className={styles.input}
      type={type} // Use type here
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </label>
);

export default Input;
