import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface AuthContextType {
  token: string | null;
  setToken: (token: string | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    const savedExpiration = localStorage.getItem('tokenExpiration');

    // Проверяем, истек ли срок действия токена
    if (savedToken && savedExpiration) {
      const expirationDate = new Date(parseInt(savedExpiration, 10));
      if (expirationDate > new Date()) {
        setToken(savedToken);
      } else {
        // Если срок действия истек, удаляем токен
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
      }
    }
  }, []);

  const handleSetToken = (newToken: string | null) => {
    if (newToken) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7); // Устанавливаем срок действия на 7 дней
      localStorage.setItem('token', newToken);
      localStorage.setItem('tokenExpiration', expirationDate.getTime().toString()); // Храним срок действия токена
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
    }
    setToken(newToken);
  };

  return (
    <AuthContext.Provider value={{ token, setToken: handleSetToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth должен использоваться в AuthProvider');
  }
  return context;
};
