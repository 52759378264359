import {BASE_URL} from "../../../api/api-сonfig";

export const getCroppedImage = (editorRef, setPreview, setCroppedImage, closeModal) => {
    if (editorRef.current) {
        const canvas = editorRef.current.getImage(); // Получаем обрезанное изображение
        const croppedImage = canvas.toDataURL(); // Преобразуем в base64

        setPreview(croppedImage); // Обновляем превью обрезанным изображением
        setCroppedImage(croppedImage); // Сохраняем обрезанное изображение
        closeModal(); // Закрываем модальное окно
    }
};

// custom-avatar-editor-utils.jsx
export const handleUpload = async (croppedImage, token, setImageUrl) => {
    if (!croppedImage) {
        alert('Сначала загрузите и отредактируйте изображение');
        return;
    }

    // Преобразуем base64 в Blob
    const blob = await fetch(croppedImage).then((res) => res.blob());

    if (!blob) {
        alert('Не удалось обработать изображение');
        return;
    }

    // Создайте круглое изображение с помощью canvas
    const img = new Image();
    img.src = croppedImage;

    img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const size = Math.min(img.width, img.height);
        canvas.width = size;
        canvas.height = size;

        // Рисуем круглую маску
        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.clip();

        // Рисуем изображение на canvas
        ctx.drawImage(img, (size - img.width) / 2, (size - img.height) / 2);

        // Получаем изображение в формате PNG
        const roundedBlob = await new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/png');
        });

        const formData = new FormData();
        formData.append('file', roundedBlob, 'avatar.png'); // Укажите имя файла, если необходимо

        try {
            const response = await fetch(`${BASE_URL}/wp-json/wp/v2/media`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`, // Убедитесь, что у вас есть токен
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Ошибка при загрузке изображения: ' + response.statusText);
            }

            const data = await response.json();
            /*console.log('Изображение успешно загружено:', data);*/
            const fileUrl = data.source_url; // URL загруженного изображения
            /*console.log(fileUrl);*/

            // Записываем ссылку в контекст
            setImageUrl(fileUrl); // Устанавливаем ссылку в контекст

        } catch (error) {
           /* console.error('Ошибка:', error);*/
            alert('Не удалось загрузить изображение');
        }
    };
};
